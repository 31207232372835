<template>
  <div>
    <div class="mb-2 d-flex">
      <div v-for="(report, index) in reports" :key="index">
        <b-button
          variant="primary"
          size="sm"
          class="d-inline mr-2 font-weight-bolder"
          @click="reportBI(report)"
          >{{ report.name }}</b-button
        >
      </div>
    </div>
    <div id="container" style="width: 100%; height: 665px"></div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import SaveDataUtil from '@/utils/saveDataToLocal';
import * as pbi from 'powerbi-client';

export default {
  name: 'dashboard',
  data() {
    return {
      reports: [],
      userInfor: null,
      reportRender: null,
    };
  },
  components: {},
  created() {
    this.mappingEnum();
    this.getReportByEmployee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'PowerBI' }]);
  },
  methods: {
    mappingEnum() {
      const userInfor = this.getEmployeeFromLocal();
      this.userInfor = userInfor;
    },
    getEmployeeFromLocal() {
      const userFromLocal = SaveDataUtil.getData('employee_info');
      if (userFromLocal) {
        const tmpInfo = JSON.parse(userFromLocal);
        return tmpInfo;
      }
    },
    getReportByEmployee: function () {
      const params = {
        employeeId: this.userInfor.id,
      };
      ApiService.query('power-bi/report/employee', {
        params,
      }).then((response) => {
        const { data } = response.data;
        for (let i = 0; i < data.length; i++) {
          this.reports.push(data[i]);
        }
        this.reportBI(data[0]);
      });
    },
    reportBI(report) {
      console.log('report', report);
      let basicFilter;

      if (report.basicFilter) {
        basicFilter = {
          ...report.basicFilter,
          filterType: pbi.models.FilterType.BasicFilter,
        };
      }
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: report.token,
        embedUrl: report.embedUrl,
        id: report.reportId,
        permissions: pbi.models.Permissions.All,
        filters: [basicFilter],
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        },
      };
      console.log('config', config);
      // Get a reference to the embedded report HTML element
      const htmlId = 'container';

      const embedContainer = document.getElementById(htmlId);

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
      // Embed the report2 and display it within the div container.
      console.log(pbi);
      powerbi.reset(embedContainer);

      const report2 = powerbi.embed(embedContainer, config);

      // Report.off removes a given event handler if it exists.
      report2.off('loaded');

      // Report.on will add an event handler which prints to Log window.
      report2.on('loaded', function () {
        console.log('Loaded');
      });

      // Report.off removes a given event handler if it exists.
      report2.off('rendered');

      // Report.on will add an event handler which prints to Log window.
      report2.on('rendered', function () {
        console.log('Rendered');
      });

      report2.on('error', function (event) {
        console.log(event.detail);

        report2.off('error');
      });

      report2.off('saved');
      report2.on('saved', function (event) {
        // Log.log(event.detail);
        if (event.detail.saveAs) {
          console.log(
            'In order to interact with the new report2, create a new token and load the new report2'
          );
        }
      });

      this.reportRender = report2;
    },
  },
};
</script>
